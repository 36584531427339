@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

.searchContainer {
  border: 0px;
  width: 40%;
  height: 55px;
  max-width: 500px;
  position: relative;
  max-width: 450px;
}

.searchContainer .searchInput{
  height: 100%;
  border-radius: 5px;
}
 form{
  height: 100%;
  width: 100%;
}

.searchInput .mui-input{
  height: 100%;
  width: 100%;
}

.searchInput .resultBox{
  display: none;
  height: auto;
  padding: 0;
  opacity: 1;
  max-height: 280px;
  overflow: hidden;
  padding: 10px 8px;
  pointer-events: auto;
}

.resultBox.visible{
  display: block;
}

.resultBox li{
  font-size: large;
  list-style: none;
  padding: 8px 12px;
  width: 100%;
  cursor: default;
  border-radius: 3px;
}

.resultBox li:hover{
  background: white;
}

.searchInput .icon{
  background: inherit;
  border: none;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 55px;
  width: 55px;
  text-align: center;
  line-height: 55px;
  font-size: 20px;
  color: #644bff;
  cursor: pointer;
}