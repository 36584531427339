:root{
  --header-height: 5vh;
  --header-before-bottom: -1.5em;
}

.header {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  backdrop-filter: blur(10px);
}
/* @media (max-width: 500px){
  .header{
    display: none;
  }
  .header:before{
    display: none;
  }
} */