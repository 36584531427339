@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css");

:root {
  --music-card-top: calc(var(--header-height) - var(--header-before-bottom) + 15px);
  --music-card-height: calc(100vh - var(--music-card-top) - 12px);
  --ar: 1/2;
  --base-top: 40;
  --base-height: 20;
  --base-ar: calc(3/5);
  --after-img-height: calc(var(--base-height)*var(--ar)/var(--base-ar)*1%);
  --after-img-top: calc(var(--base-top)*var(--ar)/var(--base-ar)*1%);
  --player-bar-height: 65px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.music-card {
  align-self: flex-start;
  position:sticky;
  height: var(--music-card-height);
  top: var(--music-card-top);
  border-radius: 6px;
  overflow: hidden;
  aspect-ratio: var(--ar);
}
/* @media (max-width: 500px){
  .music-card{
    top:0;
  }
} */
.music-card:hover #musicCardShrinkButton {
  opacity: 1;
}
.music-card > .thumbnail img {
  position: relative;
  /* left: 0; */
  /* top: 0; */
  z-index: 1;
  opacity: 0.8;
  aspect-ratio: 1/1;
  width: 100%;
}
.music-card > .thumbnail:after {
  height: var(--after-img-height);
  content: "";
  left: 0;
  top: var(--after-img-top);
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
  /* background: linear-gradient(rgba(221, 65, 127, 0), ${gradientColor}); */
}
.music-card > .wave {
  position: absolute;
  aspect-ratio: 1/1;
  width: 240%;
  opacity: 0.6;
  left: 0;
  top: 0;
  margin-left: -70%;
  margin-top: -130%;
  border-radius: 40%;
  animation: spin 3000ms infinite linear;
  animation-play-state: paused;
}
.music-card > .wave:nth-child(2) {
  top: 2%;
  animation: spin 4000ms infinite linear;
  animation-play-state: paused;
}
.music-card > .wave:nth-child(3) {
  top: 2%;
  animation: spin 5000ms infinite linear;
  animation-play-state: paused;
}
.music-card > .paused, .music-card> .paused:nth-child(3){
  animation-play-state: running;
}
.player__body {
  margin-top: 20%;
  text-align: center;
  padding: 1.5rem;
  transition: all 250ms;
}
.player__body > .title {
  color: #707070;
  position: relative;
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.player__body > .subtitle {
  font-size: 0.85rem;
  color: #90A4AE;
  padding-top: 0.375rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
}
.buttons {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 8%;
  justify-content: center;
}
/* .onCardButton{
  position: absolute;
  top:25%;
  right:0;
} */
.playpause-track,
.prev-track,
.next-track {
  padding: 20px;
  opacity: 0.8;

  /* Smoothly transition the opacity */
  transition: opacity 0.2s;
}

/* Change the opacity when mouse is hovered */
.playpause-track:hover,
.prev-track:hover,
.next-track:hover {
  opacity: 1;
}

.slider_container {
  width: 100%;
  /* max-width: 400px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* bottom: 15%; */
}

/* Modify the appearance of the slider */

.seek_slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 2px;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s, height 0.1s;
  cursor: pointer;
}

/* Modify the appearance of the slider thumb */

.seek_slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  cursor: pointer;
  border-radius: 50%;
}

/* Change the opacity when mouse is hovered */

.seek_slider:hover {
  opacity: 0.8;
  height: 3px;
}
.seek_slider:active{
  opacity: 1;
  height: 4px;
}

.seek_slider {
  width: 60%;
}

.current-time,
.total-duration {
  padding: 10px;
}

/* play pause button */

.list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.list__link {
  color: #888;
}
.body__buttons {
  padding-bottom: 2rem;
}
.body__buttons {
  padding-top: 1rem;
}
.list--buttons {
  align-items: center;
  justify-content: center;
}
.list--buttons li:nth-of-type(n + 2) {
  margin-left: 1.25rem;
}
.list--buttons a {
  padding-top: 0.45rem;
  padding-right: 0.75rem;
  padding-bottom: 0.45rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  border-radius: 50%;
}
.list--buttons a:focus,
.list--buttons a:hover {
  color: rgba(171, 2, 26, 0.95);
  opacity: 1;
  cursor: pointer;
}
.list--buttons li:nth-of-type(3) a {
  padding-top: 0.82rem;
  padding-right: 1rem;
  padding-bottom: 0.82rem;
  padding-left: 1.19rem;
  margin-left: 0.5rem;
  font-size: 1.25rem;
  color: rgba(211, 3, 32, 0.95);
}
.list--buttons li:first-of-type a,
.list--buttons li:last-of-type a {
  font-size: 0.95rem;
  color: #212121;
  opacity: 0.5;
}
.list--buttons li:first-of-type a:focus,
.list--buttons li:last-of-type a:focus,
.list--buttons li:first-of-type a:hover,
.list--buttons li:last-of-type a:hover {
  color: #d30320;
  opacity: 0.75;
}
.list__link {
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1);
}
.list__link:focus,
.list__link:hover {
  color: #d30320;
}

.ytplayer{
  position:absolute;
}

.snackbar{
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: red;
  position: absolute;
  top: 0;
  left: 25px;
}

.shuffle{
  cursor: pointer;
}

.switch__container{
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.player-bar{
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: var(--player-bar-height);
  justify-content: space-between;
  backdrop-filter: blur(10px);
}
.player-bar .song-info-container {
  width: calc(50% - 143px);
}
.player-bar .song-info {
  flex: none;
  max-width: calc(100% - 2 * 10px - 52px - 35px);
}
.player-bar-buttons{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: 'red';
  margin-right: 10px;
}
.player-bar-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}

#musicCardShrinkButton {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}