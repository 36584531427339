::-webkit-scrollbar {
    width: 20px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: inherit;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-color-light);
    border-radius: 5px;
    border: 2px solid transparent;
    background-clip: content-box;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-color);
  }
  .shimmer{
    background-color: var(--primary-color-light) !important;
    border: 1px solid transparent;
    border-radius: 5px;
  }
  .shimmer-container{
    overflow: hidden !important;
  }

  .heading.shimmer{
    width: 150px;
    height: 30px;
    margin: 0.83em 0 0.83em 10px;
  }

  /* Hide the spin buttons for number input */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
