.bodyContent.noMusic{
  grid-template-columns: 1fr;
}
.bodyContent {
  position: relative;
  top: var(--music-card-top);
  height: calc(100vh - var(--music-card-top));
  display: grid;
  grid-template-columns: auto 3fr auto;
  column-gap:1%;
  margin-left: 1vw;
  transition: 200ms;
  flex-grow: 1;
}

.routes{
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}
.routes::-webkit-scrollbar{
  width: 10px;
}
.routes.disable {
  overflow: hidden;
}

.navigation{
  display: flex;
  justify-content: flex-end;
  gap:10px;
  position: sticky;
  z-index: 1;
  top:5vh;
  margin-right: 4%;
}

.button:hover{
  cursor: pointer;
}
.MuiDrawer-paper {
  background-color: inherit !important;
}

@media (max-width: 500px){
  .bodyContent{
    grid-template-columns: 100vw;
  }
}