.song-list-container {
    position: relative;
  }
  .song-list-container li:not(.shimmer-li):hover{
    background-color: #f16fa3;
  }
  .song-list-container .heading.shimmer{
    width: 150px;
    height: 30px;
    margin: 0.83em 0 0.83em 10px;
  }
  
  .song-list-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .song-list-list li {
    display: flex;
    /* align-items: center; */
    /* padding: 10px; */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .song-list-list li.shimmer-li{
    cursor: auto;
  }
  
  .Mui-selected {
    background-color: #f0f0f0;
  }

  .song-info-container{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: row;
    gap: 10px;
    max-width: 100%;
    left: 0;
  }

  .song-info-container > .image{
    position: relative;
    background-color: black;
    height: 50px;
    aspect-ratio: 1;
    overflow: hidden;
  }
  .song-info-container img{
    height: 100%;
    width: 100%;
  }

  .song-info-container img.active{
    opacity: 0.5;
  }

  .song-info {
    flex: 1;
    max-width: calc(100% - 60px);
  }
  
  .song-title {
    /* min-width: 200px; */
    font-weight: bold;
  }
  .song-title.shimmer {
    width: 250px;
    margin-bottom: 2px;
  }
  .song-title,.song-artist{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .song-artist {
    font-size: 14px;
    color: #888;
  }
  .song-artist.shimmer {
    margin-top: 2px;
    width: 100px;
  }
  .song-title.shimmer,.song-artist.shimmer{
    height: 15px;
  }
  .music-animation {
    display: block;
    width: 500%;
    height: 400%;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    background: transparent;
    position: absolute;
    left: 50%; /* Move the button to the middle horizontally */
    top: 50%;
    transform: translate(-50%, -50%); 
    overflow: hidden;
  }