.music-recommendation{
  /* padding-left: 3%; */
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.music-cards-container{
  display:flex;
  flex-direction: column;
}
.music-cards{
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  gap:5px;
}
.music-cards-container .musicCards-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.music-cards > .music-card-item{
  position:relative;
  padding: 10px 10px 0 10px;
  /* overflow: hidden; */
  background: transparent;
  min-width: 150px;
  width: 150px;
  border-radius: 10px;
  /* border: 1px solid black; */
  transition: 0.1s ease-in;
}
.music-card-item:hover{
  cursor: pointer;
}

/* .music-card-item:before {
  z-index: 1;
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 100%;
  top:0;
  opacity:0;
  transition: opacity 1s, height 0.3s ease-in-out;
  background: linear-gradient(to top, transparent 0%, var(--primary-color) 100%);
}
.music-card-item:hover:before {
  opacity: 100%;
  height: 100%;
} */

.music-card-item .image{
  position: relative;
  overflow: hidden;
  /* padding: 5% 5% 0% 5%; */
  width: 100%;
  border-radius: 10%;
  aspect-ratio: 1/1;
}
.music-card-item .artist{
  border-radius: 50%;
}
.image > img {
  width: 100%;
  height: 100%;
  transition: 0.4s ease-in-out;
}

.music-card-item:hover img {
  transform: scale(1.08);
  /* position:absolute; */
}
.onCardButton{
  z-index: 2;
  position: absolute;
  top:80%;
  bottom:0;
  left: 40%;
  visibility: hidden;
  opacity: 0; /* Start with opacity 0, so it fades in when visible */
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Add transitions for a smooth effect */
}
.onCardButton:hover {
  cursor: pointer;
}
.music-card-item:not(.shimmer-container):hover .onCardButton {
  visibility: visible; /* Show onCardButton when hovering over music-card-item */
  opacity: 1;
  transition-delay: 0.1s; /* Add a delay before the animation starts */
}

.music-details p {
  /* Add this style to prevent text from wrapping */
  white-space: nowrap;
  /* Add this style to show ellipsis (...) for overflowing text */
  overflow: hidden;
  text-overflow: ellipsis;
}
.music-details .song-title.shimmer {
  margin-top: 2px;
  width: 150px;
  margin-bottom: 2px;
}